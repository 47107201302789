import React from "react";
import { Spinner, SpinnerSize } from "@fluentui/react";
import styles from "./LoadingSpinner.module.css";

interface LoadingSpinnerProps {
    isLoading: boolean;
    label: string;
    spinnerSize?: string;
}

export const LoadingSpinner: React.FC<LoadingSpinnerProps> = ({ isLoading, spinnerSize = "small", label }) => {

    const getSpinnerSize = (size: string): SpinnerSize => {
        switch (size.toLowerCase()) {
            case "medium":
                return SpinnerSize.medium;
            case "large":
                return SpinnerSize.large;
            case "small":
            default:
                return SpinnerSize.small;
        }
    };

    return (
        <>
            {isLoading && (
                <div className={`${styles.container}`}>
                    <Spinner size={getSpinnerSize(spinnerSize)} styles={{ root: { marginRight: 8 } }} />
                    {label}
                </div>
            )}
        </>
    );
};
