import React, { useRef } from "react";
import { useMemo, useState, useEffect } from "react";
import { Stack, IconButton, Text } from "@fluentui/react";
import DOMPurify from "dompurify";
import styles from "./Answer.module.css";
import { ChatResponse, getCitationFilePath, FeedbackDataType } from "../../api";
import { parseAnswerToHtml,generateHtmlTable } from "./AnswerParser";
import { AnswerIcon } from "./AnswerIcon";
import { useTheme } from '../../state/themecontext';
import { FeedbackPanel } from '../FeedbackPanels';

let tableHtml:string | undefined;

interface Props {
    answer: ChatResponse;
    isSelected?: boolean;
    onCitationClicked: (filePath: string) => void;
    onThoughtProcessClicked: () => void;
    onSupportingContentClicked: () => void;
    onFollowupQuestionClicked?: (question: string) => void;
    onSubmitFeedback: (feedbackDataType: FeedbackDataType) => void;
    responseID: string;
    activeAnalysisPanelTab: string | undefined;
    profile: string;
}

export const Answer = ({
    answer,
    isSelected,
    onCitationClicked,
    onThoughtProcessClicked,
    onSupportingContentClicked,
    onFollowupQuestionClicked,
    onSubmitFeedback,
    responseID,
    activeAnalysisPanelTab,
    profile,
}: Props) => {
    const { answerHtmlParts, citations, followupQuestions } = useMemo(() => {
        // Handle special answer types
        if (typeof answer.answer === 'object' && answer.answer.type === 'image') {
            return {
                answerHtmlParts: [`<div style="width: 100%; margin: 10px 0; display: flex; justify-content: center;"><img src="data:image/jpeg;base64,${answer.answer.content}" alt="Generated image" style="width: 50%; height: auto; border-radius: 8px;" /></div>`],
                citations: [],
                followupQuestions: []
            };
        }
        return parseAnswerToHtml(answer.answer, onCitationClicked);
    }, [answer]);
    const { themeColors } = useTheme();
    const [userFeedback, setUserFeedback] = useState<string>("");
    const [isFeedbackDialogVisible, setIsFeedbackDialogVisible] = useState(false);
    const [copyIcon, setCopyIcon] = useState("Copy");
    const answerRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
      setUserFeedback(userFeedback ?? "");
    }, [userFeedback]);

    const handleFeedbackType = (type: string,) => {
      if (userFeedback === type) {
        setUserFeedback("");
      } else {
        setUserFeedback(type);
        setIsFeedbackDialogVisible(true);
      }
    };

    let contentToRender: (string | JSX.Element)[];
    if (answer.error_code) {
        switch (answer.error_code) {
            case "content_filter_prompt_error":
                contentToRender = ["Query contains hateful, sexual, violent content or content that promotes self-harm. Please rephrase your query."];
                break;
            case "content_filter_response_error":
                contentToRender = ["Some or all of the content has been filtered due to content policy."];
                break;
            case "content_filter_not_applied":
                contentToRender = ["Caution: Content filtering could not be applied."];
                break;
            default:
                contentToRender = answerHtmlParts;
        }
    } else {
        contentToRender = answerHtmlParts;
    }
    const getButtonStyle = (isSelected: boolean, activeTab: string | undefined, tabName: string, iconColor: string) => ({  
        color: isSelected && activeTab === tabName ? "yellow" : iconColor,  
        fill: isSelected && activeTab === tabName ? "yellow" : "white",  
        background: isSelected && activeTab === tabName ? "grey" : themeColors.answerIconBackgroundColor,  
        height: "24px"  
    });  

    const onCopyButtonClicked = async () => {
        if (answerRef.current) {
            const htmlString = answerRef.current.innerHTML;
            const sanitizedHtmlString = DOMPurify.sanitize(htmlString);
            try {
                await navigator.clipboard.write([
                    new ClipboardItem({
                        "text/html": new Blob([sanitizedHtmlString], { type: "text/html"}),
                        "text/plain": new Blob([sanitizedHtmlString], { type: "text/plain"})
                    })
                ]);
                setCopyIcon("CheckMark");
                setTimeout(() => {
                    setCopyIcon("Copy");
                }, 2000);
            } catch (err) {
                console.error("Failed to copy: ", err);
            }
        }
    }
    
    tableHtml = generateHtmlTable(answer);

    return (
        <Stack className={`${styles.answerContainer} ${isSelected && styles.selected}`} verticalAlign="space-between">
            <Stack.Item>
                <Stack horizontal horizontalAlign="space-between">
                    <div className={styles.iconContainer}>
                        <AnswerIcon />
                        <Text className={styles.iconText}>{"Co-op GPT"}</Text>
                    </div>
                    <div>
                        <IconButton
                            style={{ color: themeColors.bulbIconColor }}
                            iconProps={{ iconName: copyIcon }}
                            title="Copy answer"
                            ariaLabel="Copy answer"
                            onClick={ async () => await onCopyButtonClicked()}
                        />
                        <IconButton
                            style={getButtonStyle(isSelected ?? false, activeAnalysisPanelTab, 'thoughtProcess', themeColors.bulbIconColor)}
                            iconProps={{ iconName: "Lightbulb" }}
                            title="Show thought process"
                            ariaLabel="Show thought process"
                            onClick={() => onThoughtProcessClicked()}
                        />
                        <IconButton
                            style={getButtonStyle(isSelected ?? false, activeAnalysisPanelTab, 'supportingContent', themeColors.clipboardIconColor)}
                            iconProps={{ iconName: "KnowledgeArticle" }}
                            title="Show supporting content"
                            ariaLabel="Show supporting content"
                            onClick={() => onSupportingContentClicked()}
                        />
                    </div>
                </Stack>
            </Stack.Item>

            <Stack.Item grow>
                <div className={styles.answerText} ref={answerRef}>
                    {contentToRender.map((part: string | JSX.Element, index: number) => (
                        <React.Fragment key={index}>
                            {typeof part === 'string' ? (
                                <span dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(part) }} />
                            ) : (
                                part
                            )}
                        </React.Fragment>
                    ))}

                     {/* Render the generated HTML table */}
                     {tableHtml && (
                        <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(tableHtml) }} />
                    )}


                </div>
            </Stack.Item>

            {!!citations.length && (
                <Stack.Item>
                    <Stack horizontal wrap tokens={{ childrenGap: 5 }}>
                        <span className={styles.citationLearnMore}>Citations:</span>
                        {citations.map((x, i) => {
                            const [text, url] = x.split('::');
                            const path = url || x; // Use url if available, otherwise use full citation
                            return (
                                <a key={i} className={styles.citation} title={x} onClick={() => onCitationClicked(path)}>
                                    {`${++i}. ${text}`} {/* Show only the text part before the :: */}
                                </a>
                            );
                        })}
                    </Stack>
                </Stack.Item>
            )}
            {/* Thumbs Section */}
            {profile !== "imagegen" && (
                <Stack.Item className={styles.thumbsContainer}>
                    <Stack horizontal verticalAlign="center" tokens={{ childrenGap: 5 }}>
                    <Text className={styles.feedBackText}>{"Are you happy with the response?"}</Text>
                    <div>
                        <IconButton
                        className={styles.thumbButton}
                        iconProps={{ iconName: userFeedback === 'positive' ? 'LikeSolid' : 'Like' }}
                        title="Thumbs Up"
                        onClick={() => handleFeedbackType('positive')}
                        />
                        <IconButton
                        className={styles.thumbButton}
                        iconProps={{ iconName: userFeedback === 'negative' ? 'DislikeSolid' : 'Dislike' }}
                        title="Thumbs Down"
                        onClick={() => handleFeedbackType('negative')}
                        />
                    </div>
                    </Stack>
                </Stack.Item>
            )}
            {/* Feedback Page Section */}
            <FeedbackPanel
                isVisible={isFeedbackDialogVisible}
                onClose={() => setIsFeedbackDialogVisible(false)}
                feedbackType={userFeedback}
                onFeedbackTypeChange={setUserFeedback}
                onSubmitFeedback={onSubmitFeedback}
                responseID={responseID}
            />
        </Stack>
    );
};
