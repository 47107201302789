import { TransformedData, saveConversationData, UserConversation, ConversationDetail, ConversationThoughtProcess } from '../api';

const chunkArray = (array: any[], size: number) => {
    const result = [];
    for (let i = 0; i < array.length; i += size) {
        result.push(array.slice(i, i + size));
    }
    return result;
};

export const transformConversations = (Conversations: string, profile: string) => {
    const parsedConversations = JSON.parse(Conversations);
    const userConversations: UserConversation[] = parsedConversations.map((conversation: any) => ({
        conversationID: conversation.conversationID,
        conversationTitle: conversation.title || conversation.conversationTitle,
        profile: profile,
        category: {}
    }));

    const conversationDetails: ConversationDetail[] = parsedConversations.map((conversation: any) => ({
        conversationID: conversation.conversationID,
        conversationHistory: conversation.messages.map((message: any) => {
            const detail: any = {
                user: message[0],
                bot: message[1].answer,
                message_id: message[1].responseID
            };

            const keys = ["top", "filterCategory", "temperature", "promptTemplate", "gptVersion", "nativeSearch", "tools", "showPreviewFeatures", "use_files"];

            keys.forEach(key => {
                if (message[1][key] !== undefined) {
                    detail[key] = message[1][key];
                }
            });

            return detail;
        }),
        files: conversation.uploadedFiles || []
    }));

    const conversationThoughtProcess: ConversationThoughtProcess[] = parsedConversations.flatMap((conversation: any) =>
        conversation.messages
            .filter((message: any) => {
                const thoughtprocess = message[1]?.thoughts;
                const datapoints = message[1]?.data_points || [];
                return thoughtprocess !== null || datapoints.length > 0;
            })
            .map((message: any) => {
                const datapoints = Array.isArray(message[1]?.data_points) ? message[1].data_points : [];
                return {
                    conversationID: conversation.conversationID,
                    message_id: message[1].responseID,
                    thoughtprocess: message[1].thoughts,
                    datapoints: datapoints.map((dataPoint: any) => ({
                        content: dataPoint.content,
                        source: dataPoint.source,
                        title: dataPoint.title
                    }))
                };
            })
    );

    const result: TransformedData = {
        userConversations,
        conversationDetails
    };

    // Add conversationThoughtProcess only if it's non-empty
    result.conversationThoughtProcess = conversationThoughtProcess.length > 0 ? conversationThoughtProcess : result.conversationThoughtProcess; 

    return result;
};

export const migrateData = async (storedConversations: string, cacheItem: string, profile: string, onMigrationComplete: () => void) => {
    if (storedConversations) {    
        let parsedConversations = JSON.parse(storedConversations);
        const conversationChunks = chunkArray(parsedConversations, 5);

        const processChunks = async () => {
            let totalSent = 0;
            for (const [index, chunk] of conversationChunks.entries()) {
                totalSent += chunk.length;
                const transformedData: TransformedData = transformConversations(JSON.stringify(chunk), profile);

                try {
                    await saveConversationData(transformedData);
                    const remainingConversations = parsedConversations.filter((_: any, i: number) => !chunk.includes(parsedConversations[i]));
                    localStorage.setItem(cacheItem, JSON.stringify(remainingConversations));
                    parsedConversations = remainingConversations;
                } catch (error) {
                    console.error("Error sending transformed data:", error);
                }
            }
        };

        await processChunks();

        const updatedStoredConversations = localStorage.getItem(cacheItem);
        if (!updatedStoredConversations || JSON.parse(updatedStoredConversations).length === 0) {
            // backup local storage before trying to migrate it incase we need to roll back
            const backupCacheItem = `${cacheItem}-backup`;  
            localStorage.setItem(backupCacheItem, storedConversations);

            onMigrationComplete();
        }
    }
};
