import { msalInstance } from '../api/auth';
import { LoginRequest } from '../config/authconfig';

interface ComponentData {
    component_name: string;
    component_title: string;
    component_type: string;
    interaction_type: string;
    interaction_value: string;
    interaction_url?: string;
}

/**
 * Checks if the user is authenticated and token is valid
 * Returns true if authenticated, false otherwise
 */
const checkAuthentication = async (): Promise<boolean> => {
    const account = msalInstance.getActiveAccount();
    const accounts = msalInstance.getAllAccounts();
    
    // If no active account but we have other accounts, try to set one
    if (!account && accounts.length > 0) {
        msalInstance.setActiveAccount(accounts[0]);
    }

    const currentAccount = msalInstance.getActiveAccount();
    
    // If still no account, return false
    if (!currentAccount) {
        console.warn('No active MSAL account found');
        // Show error dialog
        if (window.analyticsErrorDialog?.handleClickOpen) {
            window.analyticsErrorDialog.handleClickOpen();
        }
        return false;
    }

    // Check token expiration
    const idTokenClaims = currentAccount.idTokenClaims as { exp?: number };
    if (idTokenClaims?.exp && Date.now() >= idTokenClaims.exp * 1000) {
        try {
            // Try to silently acquire a new token
            await msalInstance.acquireTokenSilent({
                scopes: LoginRequest.scopes,
                account: currentAccount
            });
            return true;
        } catch (error) {
            console.warn('Token refresh failed:', error);
            // Show error dialog
            if (window.analyticsErrorDialog?.handleClickOpen) {
                window.analyticsErrorDialog.handleClickOpen();
            }
            return false;
        }
    }

    return true;
};

/**
 * Pushes an event to Google Analytics dataLayer with user authentication check
 */
export const pushToDataLayer = async (eventName: string, componentData: ComponentData) => {
    const isAuthenticated = await checkAuthentication();
    if (!isAuthenticated) return;

    const account = msalInstance.getActiveAccount();
    if (!account) return;

    // Clear any previous component data
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({ component: null });

    // Push the event with user ID
    window.dataLayer.push({
        event: eventName,
        user: {
            id: account.homeAccountId,
            loginStatus: 'logged in'
        },
        component: {
            ...componentData,
            interaction_url: componentData.interaction_url || 'n/a'
        }
    });
};

/**
 * Helper function to track component interactions
 */
export const trackComponentInteraction = (
    componentName: string,
    componentTitle: string,
    componentType: string,
    interactionType: string,
    interactionValue: string,
    interactionUrl: string = 'n/a'
) => {
    pushToDataLayer('component_interaction', {
        component_name: componentName,
        component_title: componentTitle,
        component_type: componentType,
        interaction_type: interactionType,
        interaction_value: interactionValue,
        interaction_url: interactionUrl
    });
};

/**
 * Helper function to track page initialization with authentication check
 */
export const trackPageInit = async (pageId: string, pageType: string, pageTitle: string) => {
    const isAuthenticated = await checkAuthentication();
    if (!isAuthenticated) return;

    const account = msalInstance.getActiveAccount();
    if (!account) return; 

    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
        event: 'page_init',
        user: {
            id: account.homeAccountId,
            loginStatus: 'logged in'
        },
        page: {
            id: pageId,
            type: pageType,
            url: window.location.href,
            title: pageTitle
        }
    });
}; 