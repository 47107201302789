import { Outlet, NavLink, Link } from "react-router-dom";
import styles from "./Layout.module.css";
import { IconButton } from "@fluentui/react";
import fonterralogo from "../../assets/fonterralogo.png";
import { ErrorDialog, ErrorDialogHandle } from "../../components/Dialogs";
import { useRef, useEffect } from "react";

import { ReleaseNotesUrl } from "../../config/frontendconfig";
import { trackComponentInteraction } from "../../utils/analytics";

import { useTheme } from '../../state/themecontext';
import { ThemeToggleButton } from "../../components/ThemeToggleButton";

const appVersion = import.meta.env.VITE_APP_VERSION || "Preview 0.5";

declare global {
    interface Window {
        analyticsErrorDialog?: ErrorDialogHandle;
    }
}

// track Fonterra logo clicks
const onLogoClick = (componentClicked: string) => {
    trackComponentInteraction(
        'header navigation links',
        document.title,
        'logo',
        'click',
        componentClicked,
        'https://chatgpt.apps.fonterra.com/'
    );
}

const Layout = () => {    
    const { toggleTheme, theme } = useTheme();
    const analyticsErrorDialogRef = useRef<ErrorDialogHandle>(null);

    useEffect(() => {
        if (analyticsErrorDialogRef.current) {
            window.analyticsErrorDialog = analyticsErrorDialogRef.current;
        }
    }, []);

    return (
        <div className={styles.layout}>
            <header className={styles.header} role={"banner"}>
                <div className={styles.headerContainer} >
                    <Link to="/" className={styles.headerTitleContainer} onClick={() => {onLogoClick('Dairy for life/Fonterra logo')}} >
                        <img src={fonterralogo} loading="lazy" alt="fonterra logo" style={{ width: 80, height: 64 }} />
                    </Link> 
                    <Link to="/" className={styles.headerTitleContainer} onClick={() => {onLogoClick('Co-op GPT header')}} >
                        <h3 className={styles.headerTitle}>Co-op GPT</h3>
                    </Link>
                    <div className={styles.rightGroup}>
                        <ThemeToggleButton theme={theme} toggleTheme={toggleTheme} />
                        <a href={ReleaseNotesUrl} target="_blank" rel="noopener noreferrer" className={styles.headerVersionContainer}>
                            <h5 className={styles.headerVersion}>{appVersion}</h5>
                        </a>
                    </div>
                </div>
            </header>
            <Outlet />
            <ErrorDialog
                ref={analyticsErrorDialogRef}
                title="Authentication Error"
                message="Please try refreshing/opening in a new tab. If the issue persists, sign out and back in at login.microsoftonline.com"
            />
        </div>
    );
};

export default Layout;
