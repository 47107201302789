import React, { useEffect, useRef, useCallback, useState } from 'react';
import styles from './ChatHistorySidebar.module.css';
import {
  AddSquare20Regular,
  PanelLeftContract20Regular,
  MoreVertical20Regular,
  DeleteLines20Regular 
} from "@fluentui/react-icons";
import { ChatResponse } from "../../api";
import { TooltipHost, DirectionalHint, ContextualMenu, IContextualMenuItem, TextField, Checkbox } from '@fluentui/react';
import { ITextFieldStyles } from '@fluentui/react';
import { PromptGuidance } from "../PromptGuidance";
import { PPLDisclaimer } from '../PPLDisclaimer';
import { useTheme } from '../../state/themecontext';
import { Conversation } from '../../api';
import { trackComponentInteraction } from '../../utils/analytics';

interface Props {
  chatHistory: Conversation[];
  clearChat: () => void;
  onConversationSelected: (index: number) => void;
  activeConversationIndex: number;
  isLoading: boolean;
  toggleSidebarVisibility: () => void;
  isNewChatEmpty: boolean;
  onDeleteConversation: (index: number) => void;
  handlePromptSelection: (text: string) => void;
  onRenameConversation: (index: number) => void;
  handleTitleChange: (index: number, newTitle: string) => void;
  handleTitleSave: (index: number) => void;
  editedConversationIndex: number | null;
  editedTitle: string;
  originalTitle: string;
  profile: string;
  onDeleteMultipleConversations: (indices: number[]) => void;
  isInMultiDeleteMode: boolean;
  setIsInMultiDeleteMode: (value: boolean) => void;
}

const ChatHistorySidebar: React.FC<Props> = (props) => {
  const {
    chatHistory,
    clearChat,
    onConversationSelected,
    activeConversationIndex,
    isLoading,
    toggleSidebarVisibility,
    isNewChatEmpty,
    onDeleteConversation,
    handlePromptSelection,
    onRenameConversation,
    handleTitleChange,
    handleTitleSave,
    editedConversationIndex,
    editedTitle,
    originalTitle,
    profile,
    onDeleteMultipleConversations,
    isInMultiDeleteMode,
    setIsInMultiDeleteMode,
  } = props;

  const [isPPLDisclaimerExpanded, setIsPPLDisclaimerExpanded] = React.useState(true);
  const [isPromptGuidanceExpanded, setIsPromptGuidanceExpanded] = React.useState(false);
  const [selectedChats, setSelectedChats] = useState<number[]>([]);

  const togglePPLDisclaimer = () => {
    setIsPPLDisclaimerExpanded(!isPPLDisclaimerExpanded);
    if (!isPPLDisclaimerExpanded) {
      setIsPromptGuidanceExpanded(false);
    }
  };

  const togglePromptGuidance = () => {
    setIsPromptGuidanceExpanded(!isPromptGuidanceExpanded);
    if (!isPromptGuidanceExpanded) {
      setIsPPLDisclaimerExpanded(false);
    }

    // Track prompt guidance drawer open/close
    trackComponentInteraction(
      'prompt guidance drawer',
      document.title,
      'drawer',
      'click',
      isPromptGuidanceExpanded ? 'close' : 'open'
    );
  };

  const sidebarRef = useRef<HTMLDivElement | null>(null);

  const [menuProps, setMenuProps] = useState<{ target: any, index: number } | null>(null);

  const { themeColors } = useTheme();
  const menuStyles = {
    root: {
      padding: '10px',
      backgroundColor: themeColors.historyItemMenuBackgroundColor
    },
    subComponentStyles: {
      menuItem: {
        root: {
          selectors: {
            ':hover': {
              backgroundColor: themeColors.historyItemMenuHoverColor,
              '.ms-ContextualMenu-icon': {
                color: themeColors.historyItemMenuIconColor,
              },
            },
          },
        },
        linkContent: {
          color: themeColors.historyItemMenuTextColor,
        },
        icon: {
          color: themeColors.historyItemMenuIconColor,
        },
      },
      callout: {
        root: {
          borderRadius: '8px',
          backgroundColor: themeColors.historyItemMenuBackgroundColor,
          selectors: {
            '.ms-Callout-main': {
              borderRadius: '8px',
              backgroundColor: themeColors.historyItemMenuBackgroundColor,
            },
          },
        },
      },
    },
  };

  const textFieldStyles = {
    fieldGroup: {
      borderColor: themeColors.historyItemBorderColor,
      selectors: {
        '&:after': {
          borderColor: themeColors.historyItemBorderColor,
        },
      },
    },
    field: {
      backgroundColor: themeColors.historyItemMenuBackgroundColor,
      color: themeColors.historyItemMenuTextColor,

    },
  };

  const menuItems: IContextualMenuItem[] = [
    {
      key: 'rename',
      text: 'Rename',
      onClick: () => {
        if (menuProps) {
          onRenameConversation(menuProps.index);
          hideContextMenu();
        }
      },
      iconProps: { iconName: 'Edit' }
    },
    {
      key: 'delete',
      text: 'Delete',
      onClick: () => {
        if (menuProps) {
          onDeleteConversation(menuProps.index);
          hideContextMenu();
        }
      },
      iconProps: { iconName: 'Delete' }
    },
    {
      key: 'delete-multiple',
      text: 'Delete Multiple',
      onClick: () => {
        setIsInMultiDeleteMode(true);
        hideContextMenu();
      },
      iconProps: {
        iconName: undefined,
        children: <DeleteLines20Regular style={{ marginTop: '7px', marginLeft: '-2px' }} />
      }
    }
  ];

  const showContextMenu = (event: React.MouseEvent<SVGElement>, index: number) => {
    event.stopPropagation();
    setMenuProps({ target: event.currentTarget, index });
  };

  const hideContextMenu = () => {
    setMenuProps(null);
  };

  const handleScroll = useCallback(() => {
    if (sidebarRef.current) {
      const scrollOffset = window.scrollY || document.documentElement.scrollTop;

      // Adjust the height based on the scroll position
      sidebarRef.current.style.height = `${window.innerHeight + scrollOffset - 62}px`;
      sidebarRef.current.style.top = `${62 - scrollOffset}px`;
    }
  }, []);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [handleScroll]);

  // Function to determine the display text for each conversation
  const getDisplayText = (conversation: typeof chatHistory[0]) => {
    if (conversation.messages?.length === 0) {
      return 'New Chat';
    }
    return conversation.title || conversation.conversationTitle;
  }

  const handleNewChat = () => {
    // Track the new chat button click
    trackComponentInteraction(
      'sidebar navigation',
      document.title,
      'button',
      'click',
      'Start New Chat'
    );
    
    props.clearChat();
  };

  const handleCancelMultiDelete = () => {
    setIsInMultiDeleteMode(false);
    setSelectedChats([]);
  };

  return (
    <div className={styles.sidebar} ref={sidebarRef}>
      <div className={styles.buttonsContainer}>
        <button
          onClick={handleNewChat}
          className={`${styles.newChatButton} ${props.isLoading || props.isNewChatEmpty ? styles.disabledButton : ""}`}
          disabled={props.isLoading || props.isNewChatEmpty}
        >
          <AddSquare20Regular className={styles.newChatIcon} />
          Start New Chat
        </button>

        <TooltipHost
          content="Hide sidebar"
          directionalHint={DirectionalHint.topCenter}
          id="closeSidebarTooltip"
        >
          <button
            onClick={toggleSidebarVisibility}
            className={styles.hideSidebarButton}
          >
            <PanelLeftContract20Regular className={styles.hideSidebarIcon} />
          </button>
        </TooltipHost>
      </div>

      {isInMultiDeleteMode && (
        <div className={styles.multiDeleteButtons}>
          <button 
            className={`${styles.deleteSelectedButton} ${selectedChats.length === 0 ? styles.disabledButton : ''}`}
            onClick={() => {
              onDeleteMultipleConversations(selectedChats);
            }}
            disabled={selectedChats.length === 0}
          >
            Delete Selected
          </button>
          <button 
            className={styles.cancelButton}
            onClick={handleCancelMultiDelete}
          >
            Cancel
          </button>
        </div>
      )}

      <ul>
        {chatHistory.slice(0).reverse().map((conversation, index) => {
          const actualIndex = chatHistory.length - 1 - index;
          const isActive = actualIndex === activeConversationIndex;
          const isEditing = actualIndex === editedConversationIndex;
          return (
            <li
              key={index}
              className={`${styles.conversationItem} ${isActive ? styles.activeConversation : ''} ${isLoading ? styles.conversationDisabled : ''}`}
              onClick={(e) => {
                if (!isInMultiDeleteMode || (e.target as HTMLElement).closest('.ms-Checkbox') === null) {
                  !isLoading && onConversationSelected(actualIndex);
                }
              }}
            >
              <div className={styles.conversationContent}>
                {isInMultiDeleteMode && (
                  <Checkbox
                    className={styles.deleteCheckbox}
                    styles={{
                      root: { marginRight: '8px' }
                    }}
                    onChange={(_, checked) => {
                      if (checked) {
                        setSelectedChats([...selectedChats, actualIndex]);
                      } else {
                        setSelectedChats(selectedChats.filter(idx => idx !== actualIndex));
                      }
                    }}
                    checked={selectedChats.includes(actualIndex)}
                  />
                )}
                <div className={`${styles.textContainer} ${isActive ? styles.activeText : ''}`}>
                  {isEditing ? (
                    <TextField
                      value={editedTitle}
                      onChange={(e, newValue) => handleTitleChange(actualIndex, newValue || '')}
                      onBlur={() => handleTitleSave(actualIndex)}
                      onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                          handleTitleSave(actualIndex);
                        }
                      }}
                      autoFocus
                      styles={textFieldStyles}
                    />
                  ) : (
                    <div className={styles.truncatedText}>
                      {getDisplayText(conversation)}
                    </div>
                  )}
                </div>

                {isActive ? (
                  <MoreVertical20Regular
                    className={`${styles.deleteIcon} ${isLoading ? styles.disabledIcon : ''} ${styles.hoverIcon}`}
                    onClick={(e) => showContextMenu(e, actualIndex)}
                  />
                ) : (
                  <span className={styles.deleteIconPlaceholder} />
                )}
              </div>
            </li>
          );
        })}
      </ul>

      {menuProps && (
        <ContextualMenu
          items={menuItems}
          target={menuProps.target}
          onDismiss={hideContextMenu}
          directionalHint={DirectionalHint.rightTopEdge}
          styles={menuStyles}
        />
      )}

      {(profile === "coopgptppl" || profile === "coopgptppl2") &&
        <PPLDisclaimer
          isVisible={isPPLDisclaimerExpanded}
          toggleVisibility={togglePPLDisclaimer}
        />
      }

      <PromptGuidance
        isVisible={isPromptGuidanceExpanded}
        toggleVisibility={togglePromptGuidance}
        onPromptSelect={handlePromptSelection}
        profile={profile}
      />
    </div>
  );
};

export default ChatHistorySidebar;
