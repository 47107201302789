import React, { useState, useEffect, useRef, forwardRef, useImperativeHandle } from "react";
import { Stack, TextField, ITextField } from "@fluentui/react";
import { Send28Filled } from "@fluentui/react-icons";
import styles from "./QuestionInput.module.css";
import { InputCharacterLimit } from "../../config/frontendconfig";
import { useTheme } from '../../state/themecontext';

interface Props {
    question: string;
    setQuestion: (newQuestion: string) => void;
    onSend: (question: string) => void;
    onStopThinking: () => void;
    disabled: boolean;
    isLoading?: boolean;
    placeholder?: string;
    clearOnSend?: boolean;
    uploadedFilesPresent?: Array<any>;
    onFileUploadLinkClick: (isSidePanelOpen: boolean) => void;
    conversationID: string;
    profile?: string;
}

export const QuestionInput = forwardRef(({ question, setQuestion, onSend, onStopThinking, disabled, isLoading, placeholder, clearOnSend, uploadedFilesPresent = [], onFileUploadLinkClick, conversationID, profile }: Props, ref) => {
    const [sendQuestionDisabled, setSendQuestionDisabled] = useState<boolean>(disabled || !question.trim());
    const { themeColors } = useTheme();
    const textFieldRef = useRef<ITextField | null>(null);

    useEffect(() => {
        setSendQuestionDisabled(disabled || !question.trim() || question.trim().length > InputCharacterLimit);
    }, [question, disabled]);

    const focusTextField = () => {
        if (textFieldRef.current) {
            textFieldRef.current.focus();
        }
    }

    useImperativeHandle(ref, () => ({
        focusTextField
    }));

    const sendQuestion = () => {
        if (sendQuestionDisabled) {
            return;
        }
        onSend(question);
        if (clearOnSend) {
            setQuestion("");
        }
    };

    const onEnterPress = (ev: React.KeyboardEvent<Element>) => {
        if (ev.key === "Enter" && !ev.shiftKey) {
            ev.preventDefault();
            sendQuestion();
        }
    };

    const onQuestionChange = (_ev: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
        setQuestion(newValue || "");
    };

    const onFileLinkClick = () => {
        onFileUploadLinkClick(true);
    };

    useEffect(() => {
        if (uploadedFilesPresent.length === 0) {
            setQuestion("");
            return;
        }

        const filenames = uploadedFilesPresent.join(', ') || '';
        const message = filenames ? `Please reference the following document(s) in your response (${filenames})\n` : '';
        setQuestion(message);

        if (textFieldRef.current && textFieldRef.current.value) {
            textFieldRef.current.setSelectionRange(0, 0);
        }

    }, [uploadedFilesPresent, setQuestion]);

    return (
        <Stack horizontal={false} grow={true}>
            {isLoading && (
                <button 
                className={`${styles.stopThinkingButton} ${profile !== "safegpt2" ? styles.stopThinkingButtonWithMargin : ''}`}
                onClick={onStopThinking}
                >
                    Stop Thinking
                </button>
            )}
            <Stack horizontal grow={true} className={styles.questionInputContainer}>
                <TextField
                    className={`${styles.questionInputTextArea} ${disabled ? styles.questionInputTextAreaDisabled : ""}`}
                    styles={{ field: { maxHeight: '600px', backgroundColor: themeColors.questionInputBackgroundColor, color: themeColors.questionInputTextColor } }}
                    placeholder={placeholder}
                    multiline
                    resizable={true}
                    borderless
                    value={question}
                    onChange={onQuestionChange}
                    onKeyDown={onEnterPress}
                    disabled={disabled}
                    autoFocus={true}
                    componentRef={textFieldRef}
                />
                <div className={styles.questionInputButtonsContainer}>
                    <div
                        className={`${styles.questionInputSendButton} ${sendQuestionDisabled ? styles.questionInputSendButtonDisabled : ""}`}
                        aria-label="Ask question button"
                        onClick={sendQuestion}
                    >
                        <Send28Filled primaryFill={themeColors.iconFillColor} />
                    </div>
                </div>
            </Stack>
            <Stack 
                horizontal 
                grow={true} 
                className={`${styles.questionSubtextContainer} ${profile !== "safegpt2" ? styles.questionSubtextContainerWithMargin : ''}`}
            >
                <div className={styles.inputCharacterLimit}>
                    {(question.length > InputCharacterLimit) ? question.length - InputCharacterLimit : InputCharacterLimit - question.length} Characters {(question.length > InputCharacterLimit) ? "Exceeded." : "Left."}
                </div>
            </Stack>
        </Stack>
    );
});
