import { PublicClientApplication, EventType } from '@azure/msal-browser';
import { MsalConfig, LoginRequest } from "../config/authconfig";

export const msalInstance = new PublicClientApplication(MsalConfig);
const accounts = msalInstance.getAllAccounts();
if (accounts.length > 0) {
    msalInstance.setActiveAccount(accounts[0]);
}
msalInstance.addEventCallback((event) => {
    // @ts-ignore
    if (event.eventType === EventType.LOGIN_SUCCESS && event.payload?.account){        
        // @ts-ignore
        msalInstance.setActiveAccount(event.payload?.account);
    }
    if (event.eventType === EventType.LOGIN_FAILURE) console.log(JSON.stringify(event));
});

export const acquireAccessToken = async (msalInstance: any) => {
    let activeAccount = msalInstance.getActiveAccount();
    const accounts = msalInstance.getAllAccounts();

    // Try to set an active account if we don't have one but have accounts available
    if (!activeAccount && accounts.length > 0) {
        msalInstance.setActiveAccount(accounts[0]);
        activeAccount = accounts[0];
    }

    try {
        // Try to acquire token silently
        const authResult = await msalInstance.acquireTokenSilent({
            scopes: LoginRequest.scopes,
            account: activeAccount || accounts[0]
        });
        return authResult.accessToken;
    } catch (error) {
        // Log the error
        console.error('Token acquisition failed:', error);
        return '';
    }
};

export const acquireBearerToken = async (msalInstance: any) => {
    const accessToken = await acquireAccessToken(msalInstance);
    return "Bearer " + accessToken;
};