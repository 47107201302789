import React, { useState, useCallback, useEffect, useRef, Dispatch, SetStateAction } from "react";
import { PrimaryButton, DefaultButton } from "@fluentui/react/lib/Button";
import { Toggle } from '@fluentui/react/lib/Toggle';
import { useDropzone, FileRejection, Accept } from 'react-dropzone';
import styles from "./FileUpload.module.css";
import { Max_Upload_Files, Max_Upload_File_Size_MB } from "../../config/frontendconfig";
import { Dismiss12Regular, CheckmarkCircle12Regular, ArrowLeft20Regular, ArrowRight20Regular } from "@fluentui/react-icons";
import { useTheme } from '../../state/themecontext';
import { uploadFiles, deleteConversationIndex, disableAPI } from "../../api";
import { Spinner, SpinnerSize } from "@fluentui/react";
import { ShowPreviewFeatures } from "../../config/frontendconfig";
import { TwoButtonDialog, TwoButtonDialogHandle } from "../Dialogs/TwoButtonDialog";
import { trackComponentInteraction } from '../../utils/analytics';

interface Props {
    onUpload: (files: File[], isFileUploadPanelOpen: boolean, fileNames: string[], deletedFiles: string[], uploadSessionId: string) => void;
    loading: boolean;
    conversationId: string;
    onIndexingComplete: () => void;
    profile: string;
    onIndexDeletion: () => void;
    onClearFiles: () => void;
    clearUploadSessionId: () => void;
    uploadedFiles: string[];
    setUploadedFiles: Dispatch<SetStateAction<string[]>>;
}

export const FileUploadComponent = ({ loading, onUpload, conversationId, onIndexingComplete, profile, onIndexDeletion, onClearFiles, clearUploadSessionId, uploadedFiles, setUploadedFiles }: Props) => {
    const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
    const [fileRejections, setFileRejections] = useState<FileRejection[]>([]);
    const [errorMessage, setErrorMessage] = useState('');
    const [isProcessing, setIsProcessing] = useState<boolean>(false);
    const [processingType, setProcessingType] = useState<'upload' | 'delete' | null>(null);
    const [uploadSuccess, setUploadSuccess] = useState<boolean>(false);
    const [indexDeletionSuccess, setIndexDeletionSuccess] = useState<boolean>(false);
    const [isPDFSelected, setIsPDFSelected] = useState<boolean>(false);
    const [showGuidance, setShowGuidance] = useState<'text' | 'spreadsheet' | 'image' | null>('text');
    const [showPreviewFeatures, setShowPreviewFeatures] = useState<boolean>(ShowPreviewFeatures === "true");
    const versionDialogRef = useRef<TwoButtonDialogHandle | null>(null);
    const [filesToDelete, setFilesToDelete] = useState<string[]>([]);

    const { themeColors } = useTheme();
    const dropzoneStyle = {
        '--dropzone-text-color': themeColors.dropzoneTextColor,
        '--dropzone-border-color': themeColors.dropzoneBorderColor,
        '--dropzone-hover-color': themeColors.dropzoneHoverColor,
        '--file-upload-button-active-background-color': themeColors.fileUploadButtonActiveBackgroundColor,
        '--file-upload-button-disabled-background-color': themeColors.fileUploadButtonDisabledBackgroundColor,
        '--file-upload-button-active-text-color': themeColors.fileUploadButtonActiveTextColor,
        '--file-upload-button-disabled-text-color': themeColors.fileUploadButtonDisabledTextColor,
        '--clear-files-button-disabled-background-color': themeColors.clearFilesButtonDisabledBackgroundColor,
        '--clear-files-button-active-text-color': themeColors.clearFilesButtonActiveTextColor,
        '--clear-files-button-disabled-text-color': themeColors.clearFilesButtonDisabledTextColor,
        '--clear-files-button-active-background-color': themeColors.clearFilesButtonActiveBackgroundColor,
        '--file-upload-button-disabled-border-color': themeColors.fileUploadButtonDisabledBorderColor,
        '--close-button-background-color': themeColors.closeButtonBackgroundColor,
        '--close-button-text-color': themeColors.closeButtonTextColor,
        '--close-button-hover-color': themeColors.closeButtonHoverColor,
        '--guidance-container-title': themeColors.guidanceContainerTitle,
        '--guidance-container-background': themeColors.guidanceContainerBackground,
        '--guidance-container-text-color': themeColors.guidanceContainerTextColor,
        '--guidance-container-link-color': themeColors.guidanceContainerLinkColor,
        '--success-text-color': themeColors.successTextColor,
        textAlign: 'left',
        marginTop: '20px',
        color: themeColors.closeButtonTextColor
    } as React.CSSProperties;

    useEffect(() => {
        const root = document.documentElement;
        root.style.setProperty('--scrollbar-thumb', themeColors.scrollbarThumb);
        root.style.setProperty('--scrollbar-track', themeColors.scrollbarTrack);
    }, [themeColors]);

    // Load uploaded files from local storage when the component mounts
    useEffect(() => {
        const cacheItem = profile + "allConversations";
        const storedConversations = localStorage.getItem(cacheItem);
        if (storedConversations) {
            const conversations = JSON.parse(storedConversations);
            const currentConversation = conversations.find((conv: any) => conv.conversationID === conversationId);
            if (currentConversation?.uploadedFiles) {
                setUploadedFiles(currentConversation.uploadedFiles);
            }
        }
    }, [conversationId, profile]);

    const pushDataLayerEvent = (interactionValue: string, componentType: 'button' | 'icon' = 'button') => {
        trackComponentInteraction(
            'file upload',
            'file upload',
            componentType,
            'click',
            interactionValue
        );
    };

    const clearSuccessMessages = () => {
        setUploadSuccess(false);
        setIndexDeletionSuccess(false);
    };

    const getConversationDetails = (profile: string, conversationId: string) => {
        const cacheItem = profile + "allConversations";
        const storedConversations = localStorage.getItem(cacheItem);
        if (storedConversations) {
            const conversations = JSON.parse(storedConversations);
            const currentConversation = conversations.find((convo: any) => convo.conversationID === conversationId);
            if (currentConversation?.uploadedFiles) {
                const uploadedFileCount = currentConversation.uploadedFiles.length;
                const uploadedFileExtensions = Array.from(new Set(currentConversation.uploadedFiles.map((file: any) => file.split('.').pop()?.toLowerCase() || '')));
                return { uploadedFileCount, uploadedFileExtensions };
            }
        }
        return { uploadedFileCount: 0, fileExtension: [] };
    };

    const onDrop = useCallback((acceptedFiles: File[], rejectedFiles: FileRejection[]) => {
        clearSuccessMessages();
        let exceededFiles: { name: string, size: string }[] = [];
        acceptedFiles.forEach(file => {
            const fileSizeInMB = (file.size / (1024 * 1024)).toFixed(2);
            if (parseFloat(fileSizeInMB) > Max_Upload_File_Size_MB) {
                exceededFiles.push({ name: file.name, size: fileSizeInMB });
            }
        });

        if (exceededFiles.length > 0) {
            const exceededFilesMessage = exceededFiles.map(file => `${file.name} (${file.size} MB)`).join(', ');
            setErrorMessage(`The following files exceed the ${Max_Upload_File_Size_MB} MB size limit: ${exceededFilesMessage}`);
            return;
        }

        const { uploadedFileCount, uploadedFileExtensions = [] } = getConversationDetails(profile, conversationId);
        const totalFiles = selectedFiles.length + acceptedFiles.length + uploadedFileCount;

        if (totalFiles <= Max_Upload_Files) {
            const newFiles = [...selectedFiles, ...acceptedFiles];
            const fileExtensions = new Set(newFiles.map(file => file.name.split('.').pop()?.toLowerCase() || ''));

            if (fileExtensions.size > 1 || fileExtensions.has('') || uploadedFileExtensions.length > 1) {
                setErrorMessage("You can only upload one file type at a time!");
            } else if (uploadedFileExtensions.length > 0 && fileExtensions.size === 1 && !uploadedFileExtensions.includes([...fileExtensions][0])) {
                setErrorMessage(`The uploaded file type must match the existing file type: ${uploadedFileExtensions.join(', ')}`);
            } else {
                setSelectedFiles(newFiles);
                setErrorMessage("");
            }
        } else {
            setErrorMessage(`You can only upload up to ${Max_Upload_Files} files!`);
        }
        setFileRejections(rejectedFiles);
    }, [selectedFiles]);

    const acceptedFiles: Accept = showPreviewFeatures
        ? {
            "application/pdf": [".pdf"],
            "text/csv": [".csv"],
            "application/vnd.ms-excel": [".xls"],
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [".xlsx"],
            "application/vnd.openxmlformats-officedocument.wordprocessingml.document": [".docx"],
            "image/png": [".png"],
            "image/jpeg": [".jpeg", ".jpg"],
            "image/gif": [".gif"],
        }
        : {
            "application/pdf": [".pdf"],
            "text/csv": [".csv"],
            "application/vnd.ms-excel": [".xls"],
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [".xlsx"],
            "application/vnd.openxmlformats-officedocument.wordprocessingml.document": [".docx"],
            "image/png": [".png"],
            "image/jpeg": [".jpeg", ".jpg"],
            "image/gif": [".gif"],
        };

    // Function to extract and format file types
    function getFileTypes(acceptedFiles: Accept): string {
        const fileTypes = Object.values(acceptedFiles).flat();
        return fileTypes.map(ext => ext.replace(".", "").toUpperCase()).join(", ");
    }

    const formattedFileTypes = getFileTypes(acceptedFiles);

    const fileTypesMessage: string = `Supported file types: ${formattedFileTypes}.`;
    const fileRejectionMessage: string = `You can only upload supported file types: ${formattedFileTypes}.`;

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
        accept: acceptedFiles,
        disabled: loading || isProcessing
    });

    const updateLocalStorage = (files: string[]) => {
        const cacheItem = profile + "allConversations";
        const storedConversations = localStorage.getItem(cacheItem);
        if (storedConversations) {
            const conversations = JSON.parse(storedConversations);
            const updatedConversations = conversations.map((conv: any) =>
                conv.conversationID === conversationId ? { ...conv, uploadedFiles: files } : conv
            );
            localStorage.setItem(cacheItem, JSON.stringify(updatedConversations));
        }
    };

    const handleDeleteFile = (fileName: string) => {
        setFilesToDelete(prev => [...prev, fileName]);
        pushDataLayerEvent('remove file', 'icon');
    };

    const onConfirmClick = async () => {
        if (selectedFiles.length === 0 && filesToDelete.length === 0) return;

        clearSuccessMessages();
        setIsProcessing(true);
        setProcessingType('upload');
        const fileNames = selectedFiles.map(file => file.name);
        const filesToDeleteSet = new Set(filesToDelete);
        const filteredFiles = [...fileNames, ...uploadedFiles].filter(file => !filesToDeleteSet.has(file));
        const allFileExtensions = filteredFiles
            .map(fileName => {
                const ext = fileName.split('.').pop();
                return ext ? ext.toLowerCase() : '';
            })
            .filter(ext => ext);

        try {
            const response = await uploadFiles(selectedFiles, conversationId, filesToDelete, allFileExtensions);
            let parsedResponse;
            if (typeof response === 'string') {
                parsedResponse = JSON.parse(response);
            } else {
                parsedResponse = response;
            }
            const uploadSessionId = parsedResponse.statistics.upload_session_id;
            const newUploadedFiles = Array.from(new Set([
                ...uploadedFiles.filter(file => !filesToDelete.includes(file)),
                ...selectedFiles.map(file => file.name)
            ]));
            setUploadedFiles(newUploadedFiles);
            // updateLocalStorage(newUploadedFiles);
            onUpload(selectedFiles, true, newUploadedFiles, filesToDelete, uploadSessionId);
            setSelectedFiles([]);
            setFilesToDelete([]);
            setUploadSuccess(true);
            setErrorMessage("");
            pushDataLayerEvent('upload');
        } catch (error: any) {
            if (error.message === "API is disabled") {
                if (versionDialogRef.current) {
                    versionDialogRef.current.handleClickOpen();
                }
            } else {
                console.error("Upload failed:", error);
                setErrorMessage("Upload failed. Please try again.");
            }
        } finally {
            setIsProcessing(false);
            setProcessingType(null);
            onIndexingComplete();
        }
    };

    const onClearClick = async () => {
        clearSuccessMessages();
        setErrorMessage("");
        if (uploadedFiles.length > 0) {
            setIsProcessing(true);
            setProcessingType('delete');
            try {
                await deleteConversationIndex(conversationId);
                setUploadedFiles([]);
                // updateLocalStorage([]);
                setIndexDeletionSuccess(true);
                onIndexDeletion();
                onClearFiles();
                clearUploadSessionId();
                pushDataLayerEvent('clear all files');
            } catch (error: any) {
                if (error.message === "API is disabled") {
                    if (versionDialogRef.current) {
                        versionDialogRef.current.handleClickOpen();
                    }
                }
                console.error("Index deletion failed:", error);
                setErrorMessage("Failed to clear files. Please try again.");
            } finally {
                setIsProcessing(false);
                setProcessingType(null);
            }
        }
        setSelectedFiles([]);
    };

    const handleVersionUpdateRequired = (flag: boolean) => {
        if (flag) {
            window.location.reload();
        } else {
            disableAPI();
        }
    }

    const hasFiles = uploadedFiles.length > 0 || selectedFiles.length > 0;

    return (
        <div style={dropzoneStyle}>
            <div {...getRootProps()} className={`${styles.dropzone} ${isDragActive ? styles.active : ''} ${isProcessing ? styles.disabled : ''}`}>
                <input {...getInputProps()} />
                <p>{isProcessing ? "File processing in progress..." : "Drag and drop files here, or click to select files"}</p>
            </div>
            <div className={styles.fileListsContainer}>
                {hasFiles && (
                    <div className={styles.fileListSection}>
                        <div className={styles.selectedFilesHeader}>Files:</div>
                        {uploadedFiles.map((fileName, index) => (
                            <div key={`uploaded-${index}`} className={`${styles.fileItem} ${filesToDelete.includes(fileName) ? styles.markedForDeletion : ''}`}>
                                {fileName}
                                {!filesToDelete.includes(fileName) && (
                                    <div className={styles.iconWrapper}>
                                        <div className={styles.checkmarkIconContainer} title="Uploaded file">
                                            <CheckmarkCircle12Regular />
                                        </div>
                                        <div
                                            className={`${styles.deleteIconContainer} ${styles.clickable}`}
                                            title="Mark for deletion"
                                            onClick={() => handleDeleteFile(fileName)}
                                        >
                                            <Dismiss12Regular />
                                        </div>
                                    </div>
                                )}
                            </div>
                        ))}
                        {selectedFiles.length > 0 && (
                            <div className={styles.fileListSection}>
                                <div className={`${styles.selectedFilesHeader} ${styles.filesToBeUploadedHeader}`}>Selected Files:</div>
                                {selectedFiles.map((file, index) => (
                                    <div key={`selected-${index}`} className={styles.fileItem}>
                                        {file.name}
                                        <div className={styles.iconWrapper}>
                                            <div
                                                className={`${styles.iconContainer} ${styles.clickable} ${isProcessing ? styles.disabled : ''}`}
                                                title={isProcessing ? "Processing in progress" : "Remove file"}
                                                onClick={() => !isProcessing && setSelectedFiles(prev => prev.filter((_, i) => i !== index))}
                                            >
                                                <Dismiss12Regular />
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>
                )}
                {fileRejections.length > 0 && fileRejections.map(({ file, errors }, index) => (
                    <div key={index} className={styles.fileRejection}>
                        Cannot upload {file.name}
                    </div>
                ))}
                {fileRejections.length > 0 && (
                    <div className={styles.fileRejectionMessage}>
                        {fileRejectionMessage}
                    </div>
                )}
                {errorMessage && <div className={styles.errorMessage}>{errorMessage}</div>}
            </div>
            <div className={styles.processingContainer}>
                {isProcessing && <Spinner size={SpinnerSize.large} label="Processing files..." />}
            </div>
            {!isProcessing && uploadSuccess && (
                <div className={styles.successMessage}>
                    File content is now available to be queried.
                </div>
            )}
            {!isProcessing && indexDeletionSuccess && (
                <div className={styles.successMessage}>
                    All files have been removed.
                </div>
            )}
            {(selectedFiles.length > 0 || filesToDelete.length > 0) && !isProcessing && (
                <div className={styles.uploadInstructionsContainer}>
                    Click the upload button to apply changes
                </div>
            )}
            <div className={styles.uploadContainer}>
                <PrimaryButton
                    className={styles.fileUploadButton}
                    text={processingType === 'upload' ? "Uploading..." : "Upload"}
                    onClick={onConfirmClick}
                    disabled={(selectedFiles.length === 0 && filesToDelete.length === 0) || isProcessing}
                    iconProps={processingType === 'upload' ? { iconName: 'Sync' } : undefined}
                />
                <DefaultButton
                    className={styles.clearFilesButton}
                    text={processingType === 'delete' ? "Clearing files..." : "Clear all files"}
                    onClick={onClearClick}
                    disabled={(!uploadedFiles.length && !selectedFiles.length) || isProcessing}
                    iconProps={processingType === 'delete' ? { iconName: 'Sync' } : undefined}
                />
            </div>
            <br />
            {selectedFiles.some(file => file.type === "application/pdf") &&
                <div className={styles.uploadContainer}>
                    <div className={styles.instructionText}>PDFs may take a long time to process, your patience is appreciated</div>
                </div>
            }
            <div className={styles.guidanceContainer}>
                <h2>File Upload Guidance</h2>
                <ul>
                    <li>Max Upload is 5 files with a size no bigger than 40Mb</li>
                    <li>
                        Supported Formats:
                        <ul>
                            <li>Text = PDF's & Docx</li>
                            <li>Spreadsheet = Csv, Xls & Xlsx</li>
                            <li>Image = Jpeg, Jpg & Png</li>
                        </ul>
                    </li>
                </ul>
                <h2>Format Guidance</h2>
                <div className={styles.tabContainer}>
                    <div className={styles.tabHeaders}>
                        <button
                            className={`${styles.tabButton} ${showGuidance === 'text' ? styles.active : ''}`}
                            onClick={() => setShowGuidance('text')}
                        >
                            Text
                        </button>
                        <button
                            className={`${styles.tabButton} ${showGuidance === 'spreadsheet' ? styles.active : ''}`}
                            onClick={() => setShowGuidance('spreadsheet')}
                        >
                            Spreadsheet
                        </button>
                        <button
                            className={`${styles.tabButton} ${showGuidance === 'image' ? styles.active : ''}`}
                            onClick={() => setShowGuidance('image')}
                        >
                            Image
                        </button>
                    </div>
                    {showGuidance === 'text' && (
                        <div className={styles.tabContent}>
                            <ul>
                                <li>File name shouldn't include any of the following special characters: \ / : * ? &lt; &gt; | % $ # @ & + =</li>
                                <li>Only text-based information is supported i.e. no images or graphs</li>
                                <li>When prompting use terms like 'in the uploaded file' or "in the document"</li>
                            </ul>
                        </div>
                    )}
                    {showGuidance === 'spreadsheet' && (
                        <div className={styles.tabContent}>
                            <ul>
                                <li>Each column within your file must have a header/title row.</li>
                                <li>In your prompt, directly reference the title of the document, column header or a prominent phrase within the file.</li>
                                <li>Ensure each cell contains one answer. Do not have answers split across multiple cells.</li>
                            </ul>
                        </div>
                    )}
                    {showGuidance === 'image' && (
                        <div className={styles.tabContent}>
                            <ul>
                                <li>Only Text will be extracted from the image</li>
                            </ul>
                        </div>
                    )}
                </div>
            </div>
            <TwoButtonDialog
                ref={versionDialogRef}
                title="Update Required"
                message="Your version of Co-op GPT is out of date, please refresh the page to get the latest version. Don't worry, your saved chats will still be available."
                callback={handleVersionUpdateRequired}
                cancelText="Give me a sec"
                confirmText="Refresh Now"
            />
        </div>
    );
};