import React, { useEffect, useState } from "react";
import { Pivot, PivotItem } from "@fluentui/react";
import DOMPurify from "dompurify";

import styles from "./AnalysisPanel.module.css";

import { SupportingContent } from "../SupportingContent";
import { ChatResponse, fetchUserThoughtProcess } from "../../api";
import { LoadingSpinner } from "../../components/LoadingSpinner/LoadingSpinner"
import { AnalysisPanelTabs } from "./AnalysisPanelTabs";

interface Props {
    className: string;
    activeTab: AnalysisPanelTabs;
    onActiveTabChanged: (tab: AnalysisPanelTabs) => void;
    activeCitation: string | undefined;
    citationHeight: string;
    answer: ChatResponse;
}

const pivotItemDisabledStyle = { disabled: true, style: { color: "grey" } };

export const AnalysisPanel = ({ answer, activeTab, activeCitation, citationHeight, className, onActiveTabChanged }: Props) => {
    const [isDisabledThoughtProcessTab, setIsDisabledThoughtProcessTab] = useState<boolean>(true);
    const [isDisabledSupportingContentTab, setIsDisabledSupportingContentTab] = useState<boolean>(true);
    const [isThoughtsLoading, setIsThoughtsLoading] = useState<boolean>(false);
    const [sanitizedThoughts, setSanitizedThoughts] = useState<string>("");

    useEffect(() => {
        if (!answer.thoughts) {

            const loadThoughts = async () => {
                setIsThoughtsLoading(true);

                setSanitizedThoughts("");
                try {
                    const results = await fetchUserThoughtProcess(answer.conversationID, answer.message_id);
                    const thoughts = results && results.thoughtprocess ? results.thoughtprocess :"No thought process available";                    
                    setSanitizedThoughts(DOMPurify.sanitize(thoughts));

                    answer.thoughts = thoughts
                    answer.data_points = results.datapoints;


                    setIsThoughtsLoading(false);
                } catch (error) {
                    console.error("Error loading thoughts:", error);
                    setIsThoughtsLoading(false);
                }
            };

            loadThoughts();
        } else {
            setSanitizedThoughts(DOMPurify.sanitize(answer.thoughts));
        }
        
        setIsDisabledThoughtProcessTab(!answer.thoughts && answer.thoughts!=="No thought process available");
        setIsDisabledSupportingContentTab(!(answer.data_points && answer.data_points.length > 0));

    }, [answer]);

    return (
        <Pivot
            className={className}
            selectedKey={activeTab}
            onLinkClick={pivotItem => pivotItem && onActiveTabChanged(pivotItem.props.itemKey! as AnalysisPanelTabs)}
            styles={{
                link: styles.analysisPanelTabs
            }}
        >
            <PivotItem
                itemKey={AnalysisPanelTabs.ThoughtProcessTab}
                headerText="Thought process"
                headerButtonProps={isDisabledThoughtProcessTab ? pivotItemDisabledStyle : undefined}                
            >
                {isThoughtsLoading ? (
                    <LoadingSpinner isLoading={true} label={"loading thoughts"}/>
                ):(
                    <ul className={styles.thoughtProcessList}>
                        <li className={styles.thoughtProcess}>
                            <div className={styles.thoughtProcessText} dangerouslySetInnerHTML={{ __html: sanitizedThoughts }}></div>
                        </li>
                    </ul>
                )}
                
            </PivotItem>
            <PivotItem
                itemKey={AnalysisPanelTabs.SupportingContentTab}
                headerText="Supporting content"
                headerButtonProps={isDisabledSupportingContentTab ? pivotItemDisabledStyle : undefined}
            >
                 {isThoughtsLoading ? (
                    <LoadingSpinner isLoading={true} label={"loading content"}/>
                ):(
                    <>
                    { answer.data_points && answer.data_points.length < 0 ? (
                        <SupportingContent supportingContent={answer.data_points} />
                    ) : (
                        <ul className={styles.thoughtProcessList}>
                            <li className={styles.thoughtProcess}>
                                <div className={styles.thoughtProcessText} dangerouslySetInnerHTML={{__html: "No data points available"}}></div>
                            </li>
                        </ul>
                    )

                    }
                </>
                )}
            </PivotItem>
        </Pivot>
    );
};
