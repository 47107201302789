import { msalInstance, acquireBearerToken } from "./auth";

const apiVersion = import.meta.env.VITE_API_VERSION || "v3";



interface InputData {
	genie_space_id: string;
	genie_space_name: string;
	submission_time: string;
	_id: string;
}

interface OutputData {
	key: string;
	text: string;
}


const convertData = (data: InputData[]): OutputData[] => {
  // map the cosmos data to the same type as the dropdown accepts
	return data.map((item) => ({
		key: item.genie_space_id,
		text: item.genie_space_name
	}));
};

export async function getDataProducts(): Promise<any> {
  // Gets and convert the list of data products from Cosmos. 
  try {
    const bearerToken = await acquireBearerToken(msalInstance);
    const response = await fetch(`/api/${apiVersion}/genie_mapping`, {
		method: "GET",
		headers: {
			"Content-Type": "application/json",
			Authorization: bearerToken
		},
    });

    if (!response.ok) {
		const errorData = await response.json();
		throw new Error(errorData.error || "Failed to fetch conversation data");
    }

    const responseData = await response.json();

    return convertData(responseData);
  } catch (error: any) {
    console.error("Error fetching conversation data:", error);
    throw error;
  }
}